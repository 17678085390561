import React, { Component } from 'react';
import { Carousel,Button ,Row, Col,Card,Input,Divider} from 'antd';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import './banner.scss'
import Consultation from '../Component/Consultation'
const { Meta } = Card;
const { Search } = Input;
class Banner extends React.Component {
        constructor(props){
            super(props)
            this.state={
                value:null
            }
        }
        componentDidMount(){
            document.title="首頁"
        }
        clickmas(){
            this.props.history.push('/Trade');
            // this.props.history.push('/Mas');
            window.location.reload() 
        }
        clickTrader(){
            this.props.history.push('/Trader');
            window.location.reload()  
        }
        clicc2c(){
            if(this.gettoken()){
                this.props.history.push('/Trade');
                window.location.reload()  
            }else{
                this.props.history.push('/Login');
                window.location.reload()  
            }
        }
        gettoken= () =>{
            let token =   window.localStorage.getItem('otcc-token')
            if(token&&token!=='null'){
              return true
            }else{
              return false
            }
          }
        render() {
          return (
            <div className="banner-page">
                <Carousel  effect="fade" className="banner-bannerimg banner-one-pc">
                    <div className="banner-one ">
                        <Row  className="row-connect" >
                            <Col  md={24} lg={16} className="banner-one-left">
                                    <p className="c2c-title">安全 &nbsp; ·  &nbsp;穩定&nbsp;  ·  &nbsp;可信賴</p>
                                    <p className="c2c-text">C2C數字資產交易服務提供商</p>
                                    <p> <Button block   size={'large'} onClick={()=>{this.clickmas()}} > 立即開始</Button></p>
                            </Col>
                            <Col  md={24} lg={2} >
                            </Col>
                            <Col  md={24} lg={4} >
                            <Card 
                                 hoverable
                                 className="card-div banner-one-card"
                                 onClick={()=>{this.clicc2c()}} 
                                 bordered={false}>
                                 <p><span className="rno-2-hero-nav-item-category"> 一键买币</span></p>
                                 OTC法币交易入口 <a className="rno-2-hero-nav-a"> > > 立即交易</a>
                                 <br></br>
                                
                                </Card>
                                <Card 
                                 hoverable
                                 onClick={()=>{this.clickmas()}} 
                                 className="card-div banner-one-card"
                                bordered={false}>
                                    <p><span className="rno-2-hero-nav-item-category">安全的交易平臺</span></p>
                                    拒絕凍卡
                                    <br></br>
                                    安全 &nbsp; ·  &nbsp;穩定&nbsp;  ·  &nbsp;可信賴
                                </Card>
                                <Card 
                                 hoverable
                                 className="card-div banner-one-card"
                                 onClick={()=>{this.clickTrader()}}
                                bordered={false}>
                                    <p><span className="rno-2-hero-nav-item-category">加入XPay</span></p>
                                    申請加入XPay，離財富自由更近壹點
                                    <br></br>
                                    高收益 &nbsp; ·  &nbsp;資金安全&nbsp;  ·  &nbsp;工作自由
                                </Card>
                            </Col>
                            <Col  md={24} lg={2} >
                            </Col>
                        </Row>
                       
                    </div>
                    
                </Carousel>
                <Carousel  effect="fade" className="banner-bannerimg banner-one-h5">
                <div className="banner-one ">
                        <h3>C2C數字資產交易服務提供商</h3>
                        <h4>安全 &nbsp; ·  &nbsp;穩定&nbsp;  ·  &nbsp;可信賴</h4>
                        <p> <Button type="primary" shape="round" size={'large'}
                         onClick={()=>{this.clickmas()}}
                        >立即合作</Button></p>
                    </div> 
                    <div className="banner-one ">
                        <h3>成為XPay交易員，離財富自由更近</h3>
                        <h4>高收益 &nbsp; ·  &nbsp;資金安全&nbsp;  ·  &nbsp;工作自由</h4>
                        <p> <Button type="primary" shape="round" size={'large'}
                         onClick={()=>{this.clickTrader()}}
                        >立即合作</Button></p>
                    </div> 
                </Carousel>
                <div className="connect common-connect">
                        <h4>產品與服務</h4>
                        {/* <p>XPay致力於幫助數字貨幣交易所、外匯投資平臺真正實現安全穩定的法幣出入金模塊</p> */}
                        <div>
                            <Row  className="row-connect" >
                                <Col  md={24} lg={12} >
                                     <Row>
                                        <Col span={24} >
                                        <Row className="row-connect-div">
                                            <Col className="tx-r col-img" span={8} >
                                                <img src={require('../static/VE.png')}/>
                                            </Col>
                                            <Col className="tx-l col-txt"   span={12} >
                                                <h5>用戶安全驗證</h5>
                                                <div>多重KYC認證，多種身份校驗，機器防刷，權限隔離，通過多種功能和安全策略的結合為交易雙方保駕護航</div>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col span={24} >
                                        <Row className="row-connect-div">
                                            <Col className="tx-r col-img" span={8} >
                                                <img src={require('../static/distribution.png')}/>
                                            </Col>
                                            <Col className="tx-l col-txt" span={12} >
                                                <h5>快速交易</h5>
                                                <div>支持7*24小時銀行卡、微信、支付寶等多種方式支付，保證交易雙方通過協商進行壹對壹的快速買賣交易</div>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={24} lg={12} >
                                     <Row >
                                        <Col span={24} >
                                        <Row className="row-connect-div">
                                            <Col className="tx-r col-img" span={8} >
                                                <img src={require('../static/daily.png')}/>
                                            </Col>
                                            <Col className="tx-l col-txt" span={12} >
                                                <h5>反洗錢策略</h5>
                                                <div>用戶行為大數據模型分析，可疑資金溯源，7*24小時解決緊急資金安全問題</div>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col span={24} >
                                        <Row className="row-connect-div">
                                            <Col className="tx-r col-img" span={8} >
                                                <img src={require('../static/price.png')}/>
                                            </Col>
                                            <Col className="tx-l col-txt" span={12} >
                                                <h5>資金安全</h5>
                                                <div>交易員體系完善，聚合各國專業交易團隊，資金安全，實施嚴格管控</div>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                </div>
                {/* <div className="Different common-connect bgf4" >
                        <h4>我們有什麼不同</h4>                        
                        <div className="site-card-wrapper row-connect">
                            <Row  >
                            <Col  md={24} lg={8} >
                                <Card title="完全保密" 
                                 hoverable
                                 className="card-div"
                                bordered={false}>
                                    <p> <img src={require('../static/icon_donate.png')}/></p>
                                    
                               壹旦您與我們建立合作，您的合作方案以及合作細節將完全保密，僅本人可提取相關資料與信息
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card title="定制方案"
                                 hoverable
                                 className="card-div"
                                 bordered={false}>
                                     <p> <img src={require('../static/icon_business.png')}/></p>
                                壹個客戶壹個LOGO，並且全方位多回路定制您所希望的資金解決方案，同時提供7*24小時無間斷維護
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card title="快速接入"
                                 hoverable
                                 className="card-div"
                                bordered={false}>
                                     <p> <img src={require('../static/icon_tour.png')}/></p>
                               我們將在合作方案達成當日發起技術對接，您只需配合驗證聯系方式，無需其他接入資料與啟動資金
                                </Card>
                            </Col>
                            </Row>
                        </div>
                </div> */}
                <div className="cooperation common-connect  bgf4">
                        <h4>全球合作夥伴</h4>
                       <div className="otcc-i-fus3-r ">
                       <Row  >
                            <Col  md={24} lg={4} >
                                 <img src={require('../static/cooperation1.jpeg')}/>
                            </Col>
                            <Col  md={24} lg={4} >
                                 <img src={require('../static/cooperation2.jpeg')}/>
                            </Col>
                            <Col  md={24} lg={4} >
                                 <img src={require('../static/cooperation31.png')}/>
                            </Col>
                            <Col  md={24} lg={4} >
                                 <img src={require('../static/cooperation41.png')}/>
                            </Col>
                            <Col  md={24} lg={4} >
                                 <img src={require('../static/cooperation51.png')}/>
                            </Col>
                            <Col  md={24} lg={4} >
                                 <img src={require('../static/cooperation61.png')}/>
                            </Col>
                        </Row>
                       </div>
                </div>
                {/* <Consultation  />                */}
            </div>
          );
        }
      }
    
export default Banner;