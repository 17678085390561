import React, { Component } from 'react';
import { Carousel,Button ,Row, Col,Card,Input,Divider,Tabs} from 'antd';
import './About.scss'
class About extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    componentDidMount(){
        document.title="關於我們"
    }
    render() {
      return (
        <div className="about-page">
             {/* <Carousel  effect="fade">
                    <div className="banner-one about-one">
                   
                        <h3>關於我們</h3>
                      
                        <h4>Tel : +852 64819640</h4>
                        <h4>Address : Office  No.22  Alpha  Center  providence  Mahe  Seychelles.</h4>
                    </div>
             </Carousel> */}
             <div className="about-connect common-connect " >
                                          
                        <div className="site-card-wrapper row-connect">
                            <Row  >
                           
                            <Col  md={24} lg={12} >
                               <h4>關於XPay</h4>      
                                <div style={{padding:'10px'}}>XPay隸屬於 XPay Global Limited. 旗下，母公司是經塞舌爾政府批準的加密資產服務商，提供包括加密資產托管、加密資產投資等服務，目前已服務於全球三十多個國家和地區的客戶和合作夥伴。
                                </div>
                                
                                <div style={{padding:'10px'}}>XPay成立於2019年，是壹家專業的加密資產C2C數字資產交易服務提供商， 以提供安全、穩定、可信賴的加密數字資產交易服務為企業長期願景。</div>
                               
                                <div style={{padding:'10px'}}>XPay采用分布式辦公，團隊成員由來自於多個國家的區塊鏈、金融、互聯網等多個領域，高端人才眾多，在區塊鏈和金融領域擁有豐富的技術開發和運營經驗。依托於集團資源。</div>

                                <div style={{padding:'10px'}}>XPay成立之初，便與全球區塊鏈領域眾多優秀企業達成合作，並整合了全球大量法幣交易人員，在全球全部時區，7*24小時不間斷地對外提供強大的流動性，便於客戶在任意時間開展業務。未來，XPay將持續聯合多國多領域的優秀合作夥伴，致力打造全球立體化加密資產支付生態系統。</div>
                            </Col>
                            <Col  md={24} lg={1} >

                            </Col>
                            <Col md={24} lg={8}>
                                <br></br>
                                <br></br>
                                <br></br>
                                  <img  src={require('../static/list1-.jpg')} alt="logo" />
                            </Col>
                            </Row>
                            <Row  >
                            {/* <Col  md={24} lg={24} >
                            <br></br>
                            <div style={{padding:'10px'}}>XPay采用分布式辦公，團隊成員由來自於多個國家的區塊鏈、金融、互聯網等多個領域，高端人才眾多，在區塊鏈和金融領域擁有豐富的技術開發和運營經驗。依托於集團資源，XPay成立之初，便與全球區塊鏈領域眾多優秀企業達成合作，並整合了全球大量法幣交易人員，在全球全部時區，7*24小時不間斷地對外提供強大的流動性，便於客戶在任意時間開展業務。未來，XPay將持續聯合多國多領域的優秀合作夥伴，致力打造全球立體化加密資產支付生態系統。
                            </div>
                            </Col> */}
                            <Col  md={24} lg={24} >
                            <div style={{padding:'10px'}}><span style={{paddingLeft:'20px'}}> Address : Office  Alpha  Center  providence  Mahe  Seychelles.</span>
                            </div>
                            </Col>
                            </Row>
                        </div>
              </div>
        </div>
      );
    }
  }

export default About;