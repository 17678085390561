import React from 'react';
import { Card ,message,Input,Button} from 'antd';
import copy from 'copy-to-clipboard';
import axios from '../public/axios'
import './security.scss'
class Square extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loadings:[],
            userinfo:{},
            qrcode:'',
            base32:'',
            code:''
        }
    }
    componentDidMount(){
        document.title="提幣"
       axios.get('/v1/security/google/create').then(res=>{
             if(res.code==0){
                this.setState({ qrcode:res.data.generateQR })
                this.setState({ base32: res.data.secret.base32 })
             }
          })
      }
     copy= text =>{
        copy(text)   
        message.success('復制成功');   
     }
     band = (index) =>{
         let code = this.state.code
         if(code==''){
            message.error('請輸入谷歌驗證碼');
         }
         const newLoadings = [...this.state.loadings];
         newLoadings[index] = true;
         this.setState({
           loadings: newLoadings,
         });
         axios.post('/v1/security/google/bind',{
            "code":code
        }).then(res=>{
            newLoadings[index] = false;
            this.setState({ loadings: newLoadings });
            if(res.code==0){
                message.success('綁定成功');   
                this.props.history.push('/security');
            }else{
                message.error(res.msg);
            }
        })
        
     }
    render() {
        const { loadings } = this.state;
      return (
        <div className="user-l-p set_google">
          <Card title="谷歌兩步驗證" >
                 <p className="set_google-usdt-p"> 谷歌驗證器是壹款動態口令工具，工作原理類似短信動態驗證。綁定後每30s生成壹個動態驗證碼，驗證碼可用於登錄、提現、修改安全設置等操作的安全驗證。</p>
                 <p className="set_google-usdt-p"> 1。iOS用戶登錄App Store搜索 “Authenticator” 下載。安卓用戶登錄應用商店或使用手機瀏覽器搜索 “谷歌驗證器” 下載。</p>
                 <div className="set_google-usdt-p"> 2。打開谷歌驗證器，掃描下方二維碼或手動輸入下述密鑰添加驗證令牌。
                 <br></br>
                 密鑰用於手機更換或遺失時找回谷歌驗證器，綁定前請務必將下述密鑰備份保存。
                 <br></br>
                 <br></br>
                 <div style={{'overflow':'scroll'}} className="fz-16" >
                 密鑰：{this.state.base32} 
                     <span onClick={()=>{this.copy(this.state.base32)}} className="cr06b curs"> 復制</span>
                 </div>
                 <br></br>
                 <img src={`data:image/png;base64,${this.state.qrcode}`}/>
                 <br></br>
                 </div>
                 <p className="set_google-usdt-p"> 3。
                        <Input className="googleipt"
                        placeholder="請輸入谷歌驗證碼"
                        onChange={(e)=> this.setState({ code: e.target.value })}
                        ></Input>
                        <Button type="primary" className="googlebtn" loading={loadings[0]} onClick={() => this.band(0)}>
                            綁定
                       </Button>
                 </p>
           </Card>
        </div>
      );
    }
  }

export default Square;