import React from 'react';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import axios from '../public/axios'


function Popovercontent(props) {
    return (
        <div className="curs">
            <p style={{color:'#1890ff'}} onClick={props.onClick}>法幣交易 </p>
            <p style={{color:'#1890ff'}} onClick={props.onClick2}>我的錢包 </p>
            <p style={{color:'#1890ff'}} onClick={props.onClick3} >賬戶設置 </p>
            <p style={{color:'#1890ff'}} onClick={props.onClick4}>退出</p>
      </div>
    );
  }

  export default Popovercontent;