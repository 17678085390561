import React from 'react';
import { Card ,Modal,Input,Button,message,Upload,Row, Col,Select} from 'antd';
import { LoadingOutlined, PlusOutlined,AlertOutlined} from '@ant-design/icons';


import axios from '../public/axios'
import './kycCertification.scss'
import moment from  "moment"
const AWS = require('aws-sdk');
const { Option } = Select;
const { Search } = Input;
class KycCertification extends React.Component {
    constructor(props){
        super(props)
        this.state={
            name:'',
            Selectvalue:'身份證',
            namecode:'',
            loadings:[],
            loading: false,
            loadingback: false,
            loadinghand: false,
            imageUrl:'',
            imageUrlback:'',
            imageUrlhand:'',
            file:'',
            fileback:'',
            filehand:'',
            awsurl:'',
            awsurlback:'',
            awsurlhand:'',
            kycstaus:true,
            kyctext:''
        }
    }
    componentDidMount(){
        document.title="實名認證"
        let kycstaus =   window.localStorage.getItem('Certification')
        if(kycstaus=='已認證'){
             this.setState({ kyctext: '已認證' })
             this.setState({ kycstaus: true })
        }else if(kycstaus=='正在審核中..'){
             this.setState({ kyctext: '正在審核中..' })
             this.setState({ kycstaus: true })
        }else if(kycstaus=='未認證'){
             this.setState({ kycstaus: false })
        }
        console.log(kycstaus)
      }
      randomString(len){
        //默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var tempLen = chars.length, tempStr='';
        for(var i=0; i<len; ++i){
            tempStr += chars.charAt(Math.floor(Math.random() * tempLen ));
        }
        return tempStr;
    }
    AWSupdateImg(file,index){
        var nowtime = moment().valueOf()
        let filename = 'kyc/idcard/' + nowtime + this.randomString(20) +'.png' 
        // console.log(filename)
        let s3 = new AWS.S3({
            'accessKeyId' : 'AKIAREVQNU3CEQHLNE63',
            'secretAccessKey' : '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',
            'Region': 'ap-northeast-1'
            // 'sessionToken' : 'ap-northeast-1'
         });
        //  console.log(fileobj,'s3')
          let params = {
            'Bucket': 'otcc-privacy-resource', /* required */
            'Key':filename, /* required */
            'Body': file,
            'Access-Control-Allow-Credentials': '*',
            'ACL': 'public-read',
            };
            s3.upload(params, (err, data) =>{
                 if (err) {
                    const newLoadings = [...this.state.loadings];
                    newLoadings[0] = false;
                    this.setState({ loadings: newLoadings });
                    message.error('上傳失敗');
                 } else {
                    let src = s3.getSignedUrl('getObject', {Bucket: 'otcc-privacy-resource',Key:filename})
                    let awsurl =   src.split('?')[0]
                    if(index=='1'){
                        this.setState({
                            awsurl:awsurl
                          })
                          this.AWSupdateImg(this.state.fileback,2)
                         
                    }
                    if(index=='2'){
                        this.setState({
                            awsurlback:awsurl
                          })
                          this.AWSupdateImg(this.state.filehand,3)
                    }
                    if(index=='3'){
                        this.setState({
                            awsurlhand:awsurl
                          })
                          this.submit()
                    }
                    // // 
                    // console.log(src.split('?')[0])
                    // message.success('上傳成功');
                 }
           })
    }
    beforeUpload = (file,index) =>{
       //用文件加載器加載文件
      let reader = new FileReader();
      reader.readAsDataURL(file);
        if(index=='1'){
              reader.onload = e => {
                this.setState({
                  imageUrl:e.target.result
                })
              };
            this.setState({
                file:file
              })
        }
        if(index=='2'){
          reader.onload = e => {
            this.setState({
              imageUrlback:e.target.result
            })
          };
            this.setState({
                fileback:file
           })
        }
        if(index=='3'){
            reader.onload = e => {
                this.setState({
                  imageUrlhand:e.target.result
                })
            };
            this.setState({
                filehand:file
              })
        }
       
    }
    handleChange = info => {
      return
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status === 'done') {
         
            // 
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl =>{
            // console.log(imageUrl);
             this.setState({
              imageUrl,
              loading: false,
            })
          }
          );
        }
    }
    handleChangeback = info => {
      return
        if (info.file.status === 'uploading') {
          this.setState({ loadingback: true });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
        
          getBase64(info.file.originFileObj, imageUrlback =>
            this.setState({
              imageUrlback,
              loadingback: false,
            }),
          );
        }
    }
    
    handleChangehand = info => {
      return
        if (info.file.status === 'uploading') {
          this.setState({ loadinghand: true });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrlhand =>
            this.setState({
              imageUrlhand,
              loadinghand: false,
            }),
          );
        }
    }
    typeChange = value =>{
        // console.log(value)
        this.setState({ Selectvalue: value });
    }
    submit= index =>{
        const newLoadings = [...this.state.loadings];
        newLoadings[0] = false;
        this.setState({ loadings: newLoadings });
        // console.log(this.state.awsurl)
        // console.log(this.state.awsurlback)
        // console.log(this.state.awsurlhand)
        axios.post('/v1/web/v2/cert_kyc_submit_info',{
                "kyc_name":this.state.name,
                "kyc_type": this.state.Selectvalue,
                "kyc_id_no": this.state.namecode,
                "id_front_file":this.state.awsurl,
                "id_back_file":this.state.awsurlback,
                "real_id_photo": this.state.awsurlhand,
                "vedio_file": "視頻認證文件（可選）"
            }).then(res=>{
                if(res.code==0){
                    message.success('上傳成功');
                    window.location.reload() 
                }else{
                    message.error(res.msg);
                }
            })
    }
    upsubmit = index =>{
        if(this.state.name==''){
            message.error('請輸入姓名');
            return
        }
       
        if(this.state.namecode==''){
            message.error('請輸入身份證號碼');
            return
        }
        if(this.state.imageUrl==''){
            message.error('請上傳完善證件照片');
            return
        }
        if(this.state.imageUrlback==''){
            message.error('請上傳完善證件照片');
            return
        }
        if(this.state.imageUrlhand==''){
            message.error('請上傳完善證件照片');
            return
        }
        const newLoadings = [...this.state.loadings];
        newLoadings[0] = true;
        this.setState({
        loadings: newLoadings,
        });
        this.AWSupdateImg(this.state.file,1)
     

       
    
    }
    render() {
        const uploadButton = (
            <div>
              {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">身份證正面照</div>
            </div>
          );
          const uploadButtonback = (
            <div>
              {this.state.loadingback ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">身份證反面照</div>
            </div>
          );
          const uploadButtonhand = (
            <div>
              {this.state.loadinghand ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">身份證手持照</div>
            </div>
          );
        const { imageUrl,imageUrlback,imageUrlhand,loadings,kycstaus } = this.state;
      return (
        <div className="user-l-p kycCertification">
           {!kycstaus&&<div>
           <Card title="實名認證" >
                 <p>根據反洗錢條例，交易數字貨幣務必認證個人實名信息，為快速通過實名認證，請您填寫真實有效的信息並上傳規範的證件照。</p>
                 <p className="kyc-usdt-p">
                    <span className="kyc-dis-title">姓名:</span> 
                  <Input
                    placeholder="請輸入姓名"
                    value={this.state.name}
                    size="large"
                    onChange={(e)=> this.setState({ name: e.target.value })}
                 /></p>
                 <p className="kyc-usdt-p"> 
                 <span className="kyc-dis-title"> 證件類別:</span> 
                    <Select defaultValue="身份證" onChange={this.typeChange} >
                        <Option value="身份證">身份證</Option>
                        <Option value="護照">護照</Option>
                        <Option value="其他">其他</Option>
                    </Select>
                 </p>
                 <p className="kyc-usdt-p"> 
                 <span className="kyc-dis-title"> 證件號碼:</span> 
                 <Input
                    placeholder="請輸入證件號碼"
                    value={this.state.namecode}
                    size="large"
                    onChange={(e)=> this.setState({ namecode: e.target.value })}
                 />
                 </p>
                
           </Card>
           
         
           <Card title="證件照片" style={{'borderTop':'none'}}>
                <div>
                    <Row>
                          <Col md={24} lg={8} className="Trader-free">
                                    <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={(e)=>{this.beforeUpload(e,1)}}
                                    onChange={this.handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%','height':'200px' }} /> : uploadButton}
                                </Upload>
                            </Col>
                            <Col md={24} lg={8} className="Trader-free">
                                    <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={(e)=>{this.beforeUpload(e,2)}}
                                    onChange={this.handleChangeback}
                                >
                                    {imageUrlback ? <img src={imageUrlback} alt="avatar" style={{ width: '100%','height':'200px' }} /> : uploadButtonback}
                                </Upload>
                            </Col>
                            <Col md={24} lg={8} className="Trader-free">
                                    <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={(e)=>{this.beforeUpload(e,3)}}
                                    onChange={this.handleChangehand}
                                >
                                    {imageUrlhand ? <img src={imageUrlhand} alt="avatar" style={{ width: '100%','height':'200px' }} /> : uploadButtonhand}
                                </Upload>
                            </Col>
                    </Row>
                   
                    
                   
                </div>
                 <p className="kyc-usdt-p"> <AlertOutlined></AlertOutlined>&nbsp;上傳指引</p>
                 <p className="kyc-usdt-p"> 1. 照片內容務必真實有效，上傳的證件照需露出四角，所有信息需清晰可見，不允許任何修改或遮擋。</p>
                 <p className="kyc-usdt-p"> 2. 手持證件人五官清晰可見，完整漏出雙手手臂，照片需免冠，建議不化妝，無美顏效果。</p>
                 <p className="kyc-usdt-p">
                      <Button style={{'width':'50%','height':'40px'}} onClick={()=>{this.upsubmit()}} type="primary" loading={loadings[0]} >
                         上傳
                     </Button></p>
           </Card>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           </div>} 
           {kycstaus&&<div>
            <Card title="實名認證" >
                 {/* <p className="kyc-usdt-p"> <span >姓名:&nbsp;{JSON.parse(window.localStorage.getItem('userinfo')).name}</span> </p> */}
                 <p className="kyc-usdt-p"> <span className="kyc-dis-title">{this.state.kyctext}</span></p>
             </Card>
            </div>}
        </div>
      );
    }
  }

  export default KycCertification;


  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
      console.log(file,'filefilefilefile')
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('只能上傳JPG/PNG圖片!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('上傳圖像必須小於2 MB');
    }
    return isJpgOrPng && isLt2M;
  }