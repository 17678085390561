import React from 'react';
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

import { Input, Col, Row, Select, InputNumber, DatePicker, AutoComplete, Cascader, Button, Card, message } from 'antd';


import axios from '../public/axios'
import Indexs from '../../src/page/index'
import './login.scss'
const { Option } = Select;
const { Search } = Input;

// import axios from 'axios';
class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',//手机号
      password: '', //密码
      loadings: [], //loading
      Selectvalue: '+86', //区号
      entervalue: '获取验证码', //验证码
      ellogin: true, //是否获取临时token
      eltype: 'googleCode', //判断验证码
      token: '',//临时token
      code: '',//登陆验证码，
      timer: null

    }
  }
  componentDidMount() {
    document.title = "登陸"
  }
  //临时登陆按钮
  enterLoading = index => {
    if (this.state.phone == '') {
      return message.error('請輸入妳的手機號');
    }
    if (this.state.password == '') {
      return message.error('請輸入妳的密碼');
    }

    const newLoadings = [...this.state.loadings];
    newLoadings[index] = true;
    this.setState({
      loadings: newLoadings,
    });

    axios.post('/v1/login/loginV2S1', {
      account: this.state.phone,
      password: this.state.password,
      country_code: this.state.Selectvalue
    }).then(res => {
      newLoadings[index] = false;
      this.setState({ loadings: newLoadings });
      if (res.code == 0) {
        this.setState({ ellogin: false });
        this.setState({ token: res.data.token });
        if (res.data.codeType == "Phone") {
          this.setState({ eltype: 'phoneCode' });
        } else {
          this.setState({ eltype: 'googleCode' });
        }
        this.changenumber()
      } else {
        message.error(res.msg);
      }
      // console.log(res)
    })


  };
  loginp = index => {
    if (this.state.code == '') {
      return message.error('請輸入驗證碼');
    }
    const newLoadings = [...this.state.loadings];
    newLoadings[index] = true;
    this.setState({
      loadings: newLoadings,
    });
    let token = this.state.token
    let tokentype = this.state.eltype
    let obj = {
      "data": {
        "token": token
      },
      "validata": {

      }
    }
    if (tokentype == 'googleCode') {
      obj.validata.googleCode = this.state.code
    } else {
      obj.validata.phoneCode = this.state.code
    }
    axios.post('/v1/login/loginV2SE', obj).then(res => {
      newLoadings[index] = false;
      this.setState({ loadings: newLoadings });
      if (res.code == 0) {

        window.localStorage.setItem('otcc-token', res.data.token)
        this.getinfo()
      } else {
        message.error('請輸入驗證碼');
      }
    })

  }
  getinfo() {
    axios.get('/v1/account/base_info').then(res => {
      if (res.code == 0) {
        message.success('登陸成功');
        this.goto('/wallets')
        window.location.reload()
        window.localStorage.setItem('userinfo', JSON.stringify(res.data))
      } else {
        window.localStorage.setItem('otcc-token', null)
      }
    })
  }
  //页面调整
  goto(url) {
    this.props.history.push(url);
    // this.props.history.goBack(); 回退1
    // this.props.history.go(-1);回退2
  }
  //sele选择
  handleChange = value => {
    this.setState({ Selectvalue: value });
  }
  handleonSearch = value => {
    if (this.state.entervalue !== '获取验证码') {
      return
    }
    const newLoadings = [...this.state.loadings];
    newLoadings[1] = true;
    this.setState({
      loadings: newLoadings,
    });
    axios.post('/v1/login/loginV2S1', {
      account: this.state.phone,
      password: this.state.password,
      country_code: this.state.Selectvalue
    }).then(res => {
      newLoadings[1] = false;
      this.setState({ loadings: newLoadings });
      this.changenumber()
    })
    // console.log(value)
  }
  changenumber = () => {
    let num = 60;
    let timer = setInterval(() => {
      num--;
      this.setState({ entervalue: num });
      if (num <= 0) {
        clearInterval(timer)
        this.setState({ entervalue: '获取验证码' });
      }
    }, 1000);
    this.setState({ timer: timer });
  }
  componentWillUnmount() {
    clearInterval(this.state.timer);
  }
  render() {
    const { loadings } = this.state;
    return (

      <div className="Login">
        {this.state.ellogin && <Card
          className="Login-card"
        >
          <h3 className="center">歡迎登陸 </h3>
          <br></br>
          
          {/* <Input.Password className="Loginipt" placeholder="請輸入郵箱" onChange={(e) => this.setState({ phone: e.target.value })} /> */}

          <br></br>
          {/* <Input  className="Loginipt" size="large" placeholder="name" prefix={<UserOutlined />} /> */}

          {/* <Input.Password className="Loginpsd" placeholder="請輸入密碼" onChange={(e) => this.setState({ password: e.target.value })} /> */}
          <br></br>
          <br></br>
          <Button type="primary" disabled className="Loginbtn" loading={loadings[0]} onClick={() => this.enterLoading(0)}>
            服務維護中
          </Button>
          <br></br>
          <br></br>

          <Button type="default" className="Loginbtn" onClick={() => this.goto('register')}>
                 沒有賬號？立即註冊 >
          </Button>
        </Card>
        }
        {!this.state.ellogin && <Card
          className="Login-card"
        >
          <h3 className="center">{this.state.eltype == 'googleCode' ? '請輸入谷歌驗證碼' : '請輸入短信驗證碼'} </h3>
          <br></br>
          {this.state.eltype == 'googleCode' && <Input onChange={(e) => this.setState({ code: e.target.value })} className="Loginpsd" placeholder="請輸入妳的谷歌驗證碼" />}
          {this.state.eltype == 'phoneCode' && <Search
            loading={loadings[1]}
            className="Loginipt LoginSearch"
            placeholder="請輸入驗證碼"
            enterButton={this.state.entervalue}
            size="large"
            onSearch={this.handleonSearch}
            onChange={(e) => this.setState({ code: e.target.value })}
          />}
          <br></br>
          <br></br>

          <Button type="primary" className="Loginbtn" loading={loadings[0]} onClick={() => this.loginp(0)}>
            登陆
          </Button>
          <br></br>
          <br></br>
        </Card>
        }
      </div>

    );
  }
}

export default withRouter(Login);