import React from 'react';
import { Card ,Modal,Input,Button,message,Upload,Table,Space} from 'antd';
import { LoadingOutlined, PlusOutlined,AlertOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";

import axios from '../public/axios'
import './Collectionlist.scss'
import moment from  "moment"
const AWS = require('aws-sdk');
const { confirm } = Modal;
const { Column, ColumnGroup } = Table;


class Collectionlist extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loadings:[],
            visible:false,
            payvisible:false,
            name:'', //銀行卡名稱
            bankcode:'', //銀行卡卡號
            bankname:'', //銀行名稱
            bankadress:'', //銀行地址
            payname:'',//支付寶名稱
            paycode:'',//支付寶賬號
            awsurl:'',//上傳路徑
            imageUrl:'',
            file:'',
            loading:false,
            data:[] ,//列表
            emitflag:false,
            bankid:'',
            emitsrc:false,
        }
    }
    componentDidMount(){
        document.title="收款列表"
        let kycstaus =   window.localStorage.getItem('Certification')
        if(kycstaus=='已認證'){
             
        }else{
            let that = this
            Modal.warning({
              centered:true,
              title: '提示',
              content: '請先完成實名認證信息',
              okText:'去認證',
              onOk() {
                that.props.history.push('/kycCertification');
                window.location.reload() 
              },
            });
        }
        this.getdata()
    }
    getdata(){
        axios.get('/v1/traders/payaccount/list').then(res=>{
            if(res.code==0){
                res.data.forEach((item,index)=>{
                     item.is_orders = Boolean(item.is_orders)
                     if(item.account_type=='BANKPAY'){
                        item.account_type= '銀行卡'
                        item.accout = item.platform_account_no;
                    }else{
                        item.account_type= '支付寶'
                        item.accout = item.user_id;
                    }
                })
                this.setState({ data: res.data});
            }
        })
    }
    addbankflag(){
        this.setState({ visible:true });
        this.setState({ emitflag:false });
    }
    addbank(){
        if(this.state.name==''){
            message.error('請輸入姓名');
            return
        }
        if(this.state.bankcode==''){
            message.error('請輸入銀行卡賬號');
            return
        }
        if(this.state.bankname==''){
            message.error('請輸入開戶行名稱');
            return
        }
        if(this.state.bankadress==''){
            message.error('請輸入開戶行地址：');
            return
        }
        const newLoadings = [...this.state.loadings];
        newLoadings[0] = true;
        this.setState({
            loadings: newLoadings,
        });
        if(this.state.emitflag){
            axios.post('/v1/traders/payaccount/bank/update',{
                "account_name": this.state.name,
                "platform_account_no":this.state.bankcode,
                "bank_name": this.state.bankname,
                "bank_branch_name": this.state.bankadress,
                "id":this.state.bankid
            }).then(res=>{
                if(res.code==0){
                    this.getdata()
                    newLoadings[0] = false;
                    this.setState({ loadings: newLoadings });
                    this.setState({ name:'' });
                    this.setState({ bankcode:'' });
                    this.setState({ bankname:'' });
                    this.setState({ bankadress:'' });
                    this.setState({ visible:false });
                    message.success('編輯成功');
                }else{
                    message.error(res.msg);
                }
            })
        }else{
            axios.post('/v1/traders/payaccount/bank/create',{
                "account_name":this.state.name,
                "platform_account_no": this.state.bankcode,
                "bank_name": this.state.bankname,
                "bank_branch_name": this.state.bankadress
            }).then(res=>{
                if(res.code==0){
                    this.getdata()
                    newLoadings[0] = false;
                    this.setState({ loadings: newLoadings });
                    this.setState({ name:'' });
                    this.setState({ bankcode:'' });
                    this.setState({ bankname:'' });
                    this.setState({ bankadress:'' });
                    this.setState({ visible:false });
                    message.success('添加成功');
                }else{
                    message.error(res.msg);
                }
            })
         }
    }
    addpay(){
        if(this.state.imageUrl==''){
            message.error('請上傳收款二維碼');
            return
        }
        if(this.state.payname==''){
            message.error('請輸入姓名');
            return
        }
        if(this.state.paycode==''){
            message.error('請輸入支付寶賬號');
            return
        }
        const newLoadings = [...this.state.loadings];
            newLoadings[1] = true;
            this.setState({
                loadings: newLoadings,
            });
        if(this.state.emitflag){
            if(this.state.emitsrc){
                this.AWSupdateImg(this.state.file)
            }else{
                this.emitpay()
            }
        }else{
            this.AWSupdateImg(this.state.file)
        }
    }
    //編輯支付寶哦
    emitpay(){
       axios.post('/v1/traders/payaccount/alipay/update',{
           
            "qr_link": this.state.awsurl,
            "account_name":  this.state.payname,
            "user_id": this.state.paycode,
            "id": this.state.bankid
      }).then(res=>{
             const newLoadings = [...this.state.loadings];
            newLoadings[1] = false;
            this.setState({ loadings: newLoadings });
            if(res.code==0){
                message.success('修改成功');
                this.setState({ imageUrl:'' });
                this.setState({ payname:'' });
                this.setState({ paycode:'' });
                this.setState({ emitsrc:false });
                this.setState({ payvisible:false });
                this.getdata()
            }else{
                message.error(res.msg);
            }
      })
    }
    pushaddpay(){
        axios.post('/v1/traders/payaccount/alipay/create',{
            "qr_link":this.state.awsurl,
            "account_name":this.state.payname,
            "user_id":this.state.paycode
        }).then(res=>{
            const newLoadings = [...this.state.loadings];
            newLoadings[1] = false;
            this.setState({ loadings: newLoadings });
            
            if(res.code==0){
                message.success('上傳成功');
                this.setState({ imageUrl:'' });
                this.setState({ payname:'' });
                this.setState({ paycode:'' });
                this.setState({ emitsrc:false });
                this.setState({ payvisible:false });
                this.getdata()
            }else{
                message.error(res.msg);
            }
        })
    }
    randomString(len){
        //默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var tempLen = chars.length, tempStr='';
        for(var i=0; i<len; ++i){
            tempStr += chars.charAt(Math.floor(Math.random() * tempLen ));
        }
        return tempStr;
    }
    AWSupdateImg(file,index){
        var nowtime = moment().valueOf()
        let filename = 'pay_account/ali/' + nowtime + this.randomString(20) +'.png' 
        // console.log(filename)
        let s3 = new AWS.S3({
            'accessKeyId' : 'AKIAREVQNU3CEQHLNE63',
            'secretAccessKey' : '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',
            'Region': 'ap-northeast-1'
            // 'sessionToken' : 'ap-northeast-1'
         });
        //  console.log(fileobj,'s3')
          let params = {
            'Bucket': 'otcc-privacy-resource', /* required */
            'Key':filename, /* required */
            'Body': file,
            'Access-Control-Allow-Credentials': '*',
            'ACL': 'public-read',
            };
            s3.upload(params, (err, data) =>{
                 if (err) {
                    const newLoadings = [...this.state.loadings];
                    newLoadings[1] = false;
                    this.setState({ loadings: newLoadings });
                    message.error('上傳失敗');
                 } else {
                    let src = s3.getSignedUrl('getObject', {Bucket: 'otcc-privacy-resource',Key:filename})
                    let awsurl =   src.split('?')[0]
                     this.setState({
                            awsurl:awsurl
                    })
                   if(this.state.emitsrc){
                     this.emitpay()
                   }else{
                     this.pushaddpay()
                   }
                   
                 }
           })
    }
    addpayflag(){
        this.setState({ payvisible:true });
        this.setState({ emitflag:false });
    }
    onbankCancel(){
        this.setState({ visible:false });
        this.setState({ payvisible:false });
        this.setState({ name:'' });
        this.setState({ bankcode:'' });
        this.setState({ bankname:'' });
        this.setState({ bankadress:'' });
        this.setState({ imageUrl:'' });
        this.setState({ payname:'' });
        this.setState({ paycode:'' });
        this.setState({ emitsrc:false });
        this.setState({awsurl:''})
        this.setState({file:''})
        
    }
    beforeUpload = (file,index) =>{
        
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            if(this.state.emitflag){
                this.setState({ emitsrc:true });
             }
                this.setState({
                imageUrl:e.target.result
                })
         };
         this.setState({
            file:file
        })
    }
    handleChange = info => {
        return
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          
          getBase64(info.file.originFileObj, imageUrl =>{
            // console.log(imageUrl);
            if(this.state.emitflag){
                this.setState({ emitsrc:true });
             }
             this.setState({
              imageUrl,
              loading: false,
            })
          }
          );
        }
    }
    flagclick(record,type){
        let that = this
        confirm({
            centered:true,
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: !type?'該賬戶是否關閉接單':'該賬戶是否開啟接單',
            okText:'確定',
            cancelText:'取消',
            onOk() {
                 axios.post('/v1/traders/payaccount/paystatus/set',{
                    "enable": type,
                     "id": record.id
                }).then(res=>{
                    if(res.code==0){
                        message.success(res.msg);
                        that.getdata()
                    }else{
                        message.error(res.msg);
                    }
                })
            },
            onCancel() {
              console.log('Cancel');
            },
          });
      }
      emit(record){
        this.setState({ emitflag:true });
        this.setState({ bankid:record.id });
        
          if(record.account_type=='銀行卡'){
            this.setState({ name:record.account_name });
            this.setState({ bankcode:record.platform_account_no });
            this.setState({ bankname:record.bank_name });
            this.setState({ bankadress:record.bank_branch_name });
             this.setState({ visible:true });
          }else{
            this.setState({awsurl:record.qr_link })
            this.setState({ imageUrl:record.qr_link });
            this.setState({ payname:record.account_name});
            this.setState({ paycode:record.user_id });
            this.setState({ payvisible:true });
          }
          console.log(record)
      }
      bankremove(type){
        const newLoadings = [...this.state.loadings];
        newLoadings[3] = true;
        this.setState({
            loadings: newLoadings,
        });
        axios.post('/v1/traders/payaccount/remove',{
            "user_id":'',
            "qr_link":'',
            "account_name": '',
            "id": this.state.bankid
       }).then(res=>{
            newLoadings[3] = false;
            this.setState({
                loadings: newLoadings,
            });
           if(res.code==0){
                 message.success('刪除成功');
                 this.getdata()
                 if(type=='bank'){
                   
                    this.onbankCancel()
                 }else{
                    this.onbankCancel()
                  
                 }
                 
           }else{
                message.error(res.msg);
           }
       })
      }
    render() {
        const { loadings,imageUrl,emitflag} = this.state;
        const uploadButton = (
            <div>
              {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">收款二維碼</div>
            </div>
          );
      return (
        <div className="user-l-p Collectionlist-usdt">
             <Card title="添加收款方式" >
                 <p className="coll-list-button">
                     <Button className="btn" onClick={()=>{this.addbankflag()}} type="primary"  >
                         添加銀行卡
                     </Button>
                     <Button className="btn" onClick={()=>{this.addpayflag()}} type="primary"  loading={loadings[0]} >
                         添加支付寶
                     </Button>
                 </p>
             </Card>
             <br></br>
             <br></br>
             <br></br>
             <Card title="收款列表" >
                 <Table style={{'overflowX':'scroll','background':'white'}} 
                    pagination={{ simple: true,hideOnSinglePage:true }}
                   dataSource={this.state.data} >
                        <Column
                        title="類型"
                        dataIndex="account_type"
                        key="account_type"
                        render={(text, record) => (
                            <a size="middle">
                                {text}
                            </a>
                          )}
                        />
                        <Column title="姓名" dataIndex="account_name" key="account_name" />
                        <Column title="賬號" dataIndex="accout" key="accout" />
                        <Column title="銀行名稱" dataIndex="bank_name" key="bank_name" />
                        <Column title="地址" dataIndex="bank_branch_name" key="bank_branch_name" />
                        <Column title="今日收款" dataIndex="collection_line" key="collection_line" />
                        <Column title="是否接單" dataIndex="is_orders" key="is_orders"
                         render={(text, record) => (
                            <div>
                            {text &&<a  onClick={()=> {this.flagclick(record,false)}} style={{'color':'green'}}>關閉</a>}
                            {!text &&<a onClick={()=> {this.flagclick(record,true)}} style={{'color':'red'}}> 開啟</a>}
                            </div>
                          )}
                        />
                        <Column title="編輯" dataIndex="action" key="action" 
                            render={(text, record) => (
                                <div>
                                  <a onClick={()=> {this.emit(record)}}> 編輯 </a> 
                                </div>
                              )}
                        />
                   </Table>
             </Card>
             <Modal
                centered
                title={emitflag?'編輯銀行卡':'添加銀行卡'}
                visible={this.state.visible}
                footer={null}
                maskClosable
                onCancel={()=>{this.onbankCancel()}}
                >
                <p><Input
                    placeholder="請輸入姓名："
                    value={this.state.name}
                    size="large"
                    onChange={(e)=> this.setState({ name: e.target.value })}
                 /></p>
                  <p><Input
                    placeholder="銀行卡賬號："
                    value={this.state.bankcode}
                    size="large"
                    onChange={(e)=> this.setState({ bankcode: e.target.value })}
                 /></p>
                 <p><Input
                    placeholder="開戶行名稱："
                    value={this.state.bankname}
                    size="large"
                    onChange={(e)=> this.setState({ bankname: e.target.value })}
                 /></p>
                 <p><Input
                    placeholder="開戶行地址："
                    value={this.state.bankadress}
                    size="large"
                    onChange={(e)=> this.setState({ bankadress: e.target.value })}
                 /></p>
                 {emitflag&& <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.bankremove('bank')}} type="primary"  loading={loadings[3]} >
                        刪除
                     </Button>
                 </p>} 
                 <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.addbank()}} type="primary"  loading={loadings[0]} >
                       {emitflag?'修改':'添加'}  
                     </Button>
                 </p>
            </Modal>
            <Modal
                centered
                title={emitflag?'編輯支付寶':'添加支付寶'}
                visible={this.state.payvisible}
                footer={null}
                maskClosable
                onCancel={()=>{this.onbankCancel()}}
                >
                  <p>
                         <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={(e)=>{this.beforeUpload(e,1)}}
                                    onChange={this.handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%','height':'200px' }} /> : uploadButton}
                                </Upload>
                  </p>
                 <p><Input
                    placeholder="請輸入姓名："
                    size="large"
                    value={this.state.payname}
                    onChange={(e)=> this.setState({ payname: e.target.value })}
                 /></p>
                 <p><Input
                    placeholder="支付寶賬號："
                    value={this.state.paycode}
                    size="large"
                    onChange={(e)=> this.setState({ paycode: e.target.value })}
                 /></p>
                 {emitflag&& 
                 <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.bankremove('pay')}} type="primary"  loading={loadings[3]} >
                        刪除
                     </Button>
                 </p>} 
                 <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.addpay()}} type="primary"  loading={loadings[1]} >
                         {emitflag?'修改':'添加'}  
                     </Button>
                 </p>
            </Modal>
        </div>
      );
    }
  }

export default Collectionlist;


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  