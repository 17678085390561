import React from 'react';
import { Carousel,Button ,Row, Col,Card,Input,Divider,Collapse} from 'antd';
import Agreement from '../Component/agreement';
import Bsagreement from '../Component/bsagreement';
import './Faq.scss'
const { Panel } = Collapse;
class Faq extends React.Component {
    constructor(props){
        super(props)
        this.state={
            currentIndex:1
        }
    }
    entercurrentIndex = index => {
        this.setState({ currentIndex: index });
    }
    componentDidMount(){
        document.title="幫助中心"
    }
    render() {
      return (
        <div className="faq-page">
            <div className="Different common-connect " >
                        <div className="site-card-wrapper row-connect">
                            <Row  >
                            <Col className="tx-l faq-query" md={24} lg={8} >
                               <h4>經常問的問題</h4>
                               <p>最常見的問題和解答</p>
                            </Col>
                            <Col md={24} lg={8}>
                               
                            </Col>
                            <Col md={24} lg={8} className="faq-yiwen">
                                <img src={require('../static/wenhao.png')}/>
                            </Col>
                            </Row>
                        </div>             
                        <div className="site-card-wrapper row-connect" >
                            <Row  style={{'justifyContent':'center'}}>
                            <Col  md={24} lg={8} >
                                <Card  
                                 hoverable
                                 onClick={() => this.entercurrentIndex(1)}
                                 style={{borderColor: (this.state.currentIndex==1) ? "#448df6" : "#f0f0f0",color:(this.state.currentIndex==1) ? "#448df6" : "black"}}
                                 
                              >
                                    Q&A常見問題
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card 
                                 hoverable
                                 onClick={() => this.entercurrentIndex(2)}
                                 style={{borderColor: (this.state.currentIndex==2) ? "#448df6" : "#f0f0f0",color:(this.state.currentIndex==2) ? "#448df6" : "black"}}
                                 >
                                     服務協議
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card 
                                 hoverable
                                 onClick={() => this.entercurrentIndex(3)}
                                 style={{borderColor: (this.state.currentIndex==3) ? "#448df6" : "#f0f0f0",color:(this.state.currentIndex==3) ? "#448df6" : "black"}}
                                >
                                    交易員中心
                                </Card>
                            </Col>
                            </Row>
                        </div>
                        {this.state.currentIndex==1 &&<div className="site-card-wrapper row-connect tx-l">
                            <Row  >
                            <Col className="faq-query" md={24} lg={24} >
                            <br></br>
                                <Card  
                                 bordered={false}
                                >
                                    Q&A常見問題
                                </Card>
                            </Col> 
                            <Col md={24} lg={24} className="faq-query">
                            <br></br>
                             <Collapse  bordered={false} accordion style={{'background':'white'}}>
                                    <Panel header="如何註冊賬戶？" key="1">
                                    <div className="lih-50">進入 XPay 官網，點擊“註冊”按鈕，輸入手機號和獲取的驗證碼，閱讀並同意《XPay 服務協議》點擊“註冊”，此時賬號註冊成功並直接跳轉登陸成功頁面。</div>
                                    </Panel>
                                    <Panel header="如何提交KYC資料？" key="2">
                                    <div className="lih-50">登陸成功後進入個人中心，點擊實名認證“設置按鈕”。按照網頁要求填寫/上傳個人 資料，提交後等待審核即可。</div>
                                    </Panel>
                               </Collapse>
                            </Col>
                            </Row>
                        </div>}
                        {this.state.currentIndex==2 &&<div className="site-card-wrapper row-connect tx-l">
                            <Row  >
                            <Col className="faq-query" md={24} lg={24} >
                            <br></br>
                                <Card  
                                 bordered={false}
                                >
                                    Q&A服務協議
                                </Card>
                            </Col> 
                            <Col md={24} lg={24} className="faq-query">
                            <br></br>
                             <Collapse  bordered={false} accordion style={{'background':'white'}}>
                                    <Panel header="XPay交易員服務協議" key="1">
                                   <Agreement/>
                                    </Panel>
                                    <Panel header="壹鍵買賣交易服務協議" key="2">
                                    <Bsagreement/>
                                    </Panel>
                               </Collapse>
                            </Col>
                            </Row>
                        </div>}
                        {this.state.currentIndex==3 &&<div className="site-card-wrapper row-connect tx-l">
                            <Row  >
                            <Col className="faq-query" md={24} lg={24} >
                            <br></br>
                                <Card  
                                 bordered={false}
                                >
                                    Q&A交易員中心
                                </Card>
                            </Col> 
                            <Col md={24} lg={24} className="faq-query">
                            <br></br>
                             <Collapse  bordered={false} accordion style={{'background':'white'}}>
                                    <Panel header="需繳納多少保證金？" key="1">
                                    <div className="lih-50">登陸賬號後需要繳納 7000USDT 保證金才能進入主頁開始接單</div>
                                    </Panel>
                                    <Panel header="接單需預存多少 USDT？" key="2">
                                        <div className="lih-50">因 USDT 存入數量需大於客戶下單量才可被分配接單，建議您初期可存入 1-2 萬個USDT。當 USDT 低於 7000 個,請及時存入,避免影響接單。目前XPay單筆最大交易額為 5 萬元，約 7000USDT</div>
                                    </Panel>
                                    <Panel header="平臺支持哪些類型收付款方式？" key="3">
                                        <div className="lih-50">XPay目前支持銀行卡、支付寶兩種收付款方式。</div>
                                    </Panel>
                                    <Panel header="平臺可以綁定的收付款賬號數量？" key="4">
                                       <div className="lih-50">至少準備壹張銀行卡或壹個支付寶賬號，上不封頂。</div>
                                    </Panel>
                                    <Panel header="壹個收付賬號可接多少單？" key="5">
                                       <div className="lih-50">在線接單時交易員只能選擇壹個支付寶、壹張銀行卡進行收款，可接入多個買單和壹個賣單。當接入用戶賣出訂單，您進行付款後需點擊確認付款按鈕，才可接到下壹筆賣單。</div>
                                    </Panel>
                                    <Panel header="如何綁定銀行卡？" key="6">
                                       <div className="lih-50">進入收款賬戶進行銀行卡綁定，輸入本人實名姓名、開戶銀行、開戶支行、銀行賬號。XPay建議您務必綁定真實的本人信息，否則將有可能導致客戶頻繁取消訂單或資產無法追訴等風險</div>
                                    </Panel>
                                    <Panel header="如何提高交易員接單量？？" key="7">
                                        <div className="lih-50">
                                            1. 綁定多種收付方式（本人銀行卡、支付寶），分配率更高。<br></br>
                                            2. 加快訂單處理速度,如加快確認收款及確認付款速度,以提升交易員排名。<br></br>
                                            3. 幣量越充足，派單幾率也會更高
                                        </div>
                                        <br></br>
                                   </Panel>
                                   <Panel header="綁訂多個支付寶但卻是同壹個實名認證的用戶,是否可增加接單機率？" key="8">
                                   <div className="lih-50">不同的支付寶是可以增加接單機率的，但是註意，同壹個實名認證下的總帳號與子帳號對於支付寶來說額度是共享的，請務必設好上限以及風控值，避免支付寶被凍結的情況發生。</div>
                                   </Panel>
                                   <Panel header="什麼情況會凍結幣資產？" key="9">
                                   <div className="lih-50">交易中的訂單、申訴中的訂單或發起提幣申請時，對應的 USDT 資產會被凍結。</div>
                                   </Panel>
                                   <Panel header="為什麼我的幣凍結時長不壹致？" key="10">
                                   <div className="lih-50">
                                            1. 客戶超時的訂單，交易員確認未收到款項，系統會自動取消訂單並且幣將自動解凍。<br></br>
                                            2. 客戶取消訂單，幣馬上自動解凍。<br></br>
                                            3. 如涉及嚴重的申訴問題，幣資產將可能延長凍結時間，具體時間請屆時咨詢官方客服。
                                   </div>
                                   <br></br>
                                   </Panel>
                                   <Panel header="提幣處理時間多長？" key="11">
                                   <div className="lih-50">當前提幣申請均在兩小時內時間處理，如涉及申訴未解決的提幣申請將延長處理。具體情況屆時聯系官方客服咨詢。</div>
                                   </Panel>
                               </Collapse>
                            </Col>
                            </Row>
                        </div>}
                </div>
        </div>
      );
    }
  }

export default Faq;