import axios from 'axios';

import qs from 'qs'

axios.timeout = 600000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'  
axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.BASE_URL

axios.interceptors.request.use(function (config) {
  let token =  window.localStorage.getItem('otcc-token')
  if (token) {
      config.headers.Authorization = `Bearer ${token}`
  }
  qs.stringify(config.data)
  return config
}, function (error) {
  return Promise.reject(error)
})
axios.interceptors.response.use(function (response) { 
    if(response.data.code=='1001'){
      window.localStorage.setItem('otcc-token',null)
    //     router.push({
    //       path:'/login',
    //   })
    }
  return response.data
}, error => {
  if(error&&error.response){
    // switch(error.response.status){
    //   case 1001:
    //   router.push({
    //     path:'/phoneLogin',
    //     query:{
    //       type:'reload' 
    //     }
    //  })
    //  break;
    // }
  }
   
  return Promise.reject(error) 
})

export default axios

