import React from 'react';
import './agreement.scss'
class Bsagreement extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    render() {
      return (
       <div className="agreement-compon">
           <p>
                <b>本協議系由您與廣告方之間就在XPay平臺（以下簡稱“平臺”）上使用壹鍵買賣功能所進行的有關“標的數字資產”交易事宜所達成的壹致合意，該協議對買賣雙方均具有法律約束力。</b>
           </p>
           <br></br>
            <p>
              <b> 廣告方，即個人用戶在平臺繳納壹定數額的保證金後，發布數字資產購買或出售廣告信息。平臺面向有買賣需求的用戶，對廣告信息進行排隊展示，買賣雙方自行獨立決定是否交易。</b>
           </p>
           <br></br>
              <h3>重要提示：</h3>
           <br></br>
           <p>
              (1).您確認並同意：您壹經點擊勾選本界面的“我已知曉並同意《壹鍵買賣交易服務協議》”按鈕並點擊購買，即視為您已同意簽署本協議，本協議即已對您產生約束力。
           </p>
           <br></br>
           <p>
              (2).本平臺的宗旨是在不違反塞舌爾共和國相關法律法規的前提下，盡可能地為全球廣大數字資產愛好者及用戶提供專業、安全、可信賴的數字資產交易及數字資產管理服務。如果您所在國家或地區屬於監管明確禁止數字資產交易的法域或屬於被列入相關受制裁國家名單的，您被禁止使用本平臺項下的所有服務。
           </p>
           <br></br>
           <p>
              (3).凡使用本平臺服務的用戶，在交易前均應知悉：數字資產不以任何政府或中央銀行為後盾支持，且數字資產價格波動大，持有或使用數字資產具有較高的風險。在作出交易決策前，您應理性審慎自身的財務狀況以判斷是否適合您。如因數字資產價格波動，導致您的投資發生損失或全部損失的，以上風險均需您自行承擔，平臺不對此承擔任何責任。
           </p>
           <br></br>
           <p>
              (4).交易由您本人主動發起，向廣告方購買或出售數字資產。整個交易過程中，平臺作為獨立第三方，亦不會參與到您與廣告方的交易兌換過程中，如因上述某個交易環節出現問題的，均應由交易雙方自行協商解處理，平臺不對由此引發的任何問題承擔連帶責任，任壹方均不得就此向平臺追究任何責任。
           </p>
           <br></br>
           <br></br>
            <h3>第壹條 服務說明</h3>
           <br></br>
           <p>
              在交易開始前，您須全部知悉下述流程及步驟，並且在您首次參與使用本服務功能時，您應認真閱讀本平臺的服務說明。您壹經點擊使用平臺上的壹鍵買賣“買入”或“賣出”按鈕時，即視為您已理解並接受《服務說明》及本協議的內容。
           </p>
           <br></br>
            <p>
             您知悉並同意：您買賣數字資產，系由您與廣告方自行獨立交易完成。
           </p>
           <br></br>
            <h3>第二條 交易流程</h3>
           <br></br>
           <p>
              <b>1.報價確認</b>
           </p>
           <br></br>
            <p>
             1.您在相應界面輸入買賣的金額或者數量，平臺系統會返回您直接欲買入或賣出的標的數字資產的報價；
           </p>
           <br></br>
            <p>
             2.您在輸入買賣數量或金額後，從獲取報價到實際點擊“買入”或“賣出”期間，當前報價的時效性最長為1分鐘。否則，超時點擊購買的話，該報價會提示價格失效。如您仍需購買的話，需重新考慮是否接受新的報價。
           </p>
           <br></br>
           <p>
              <b>2.訂單生成</b>
           </p>
           <br></br>
            <p>
             1.您在勾選我已知曉並同意本協議按鈕後，確認報價並點擊“買入”或”賣出”標的數字資產後，訂單即生成。
           </p>
           <br></br>
           <p>
              <b>3.訂單完成</b>
           </p>
           <br></br>
            <p>
             1.當您買入時，您在交易訂單中向廣告方完成支付並點擊“ 已完成付款”後，廣告方在訂單有效時間內點擊“放行”，訂單即完成；
           </p>
           <br></br>
            <p>
             2.當您賣出時，廣告方在交易訂單中向您完成支付並點擊“ 已完成付款”後，您在訂單有效時間內點擊“放行“，訂單即完成。
           </p>
           <br></br>
            <p>
             3.您需特別註意：標的數字資產作為高風險品種，價格隨時存在較大波動。為確保交易的時效性和價格相對公平性，您在點擊買賣標的數字資產的報價的時效性只能保留15分鐘（即：您下單完成到放幣的時間需控制在15分鐘內）。
           </p>
           <br></br>
            <h3>第三條 責任免除及限制</h3>
           <br></br>
           <p>
              1.本平臺的宗旨是在不違反塞舌爾共和國相關法律法規的前提下，盡可能地為全球廣大數字資產愛好者及用戶提供專業、安全、可信賴的數字資產交易及數字資產管理服務。如果您所在國家或地區屬於監管明確禁止數字資產交易的法域或屬於被列入相關受制裁國家名單的，您被禁止使用本平臺項下的所有服務；
           </p>
           <br></br>
           <p>
              2.凡使用本平臺服務的用戶，在交易前均應知悉：數字資產不以任何政府或中央銀行為後盾支持，且數字資產價格波動大，持有或使用數字資產具有較高的風險。在作出交易決策前，您應理性審慎自身的財務狀況以判斷是否適合您。如因數字資產價格波動，導致您的投資發生損失或全部損失的，以上風險均需您自行承擔，平臺不對此承擔任何責任；
           </p>
           <br></br>
           <p>
              3.交易由您本人主動發起，向廣告方購買或出售數字資產。整個交易過程中，平臺作為獨立第三方，亦不會參與到您與廣告方的交易兌換過程中，如因上述某個交易環節出現問題的，均應由交易雙方自行協商解處理，平臺不對由此引發的任何問題承擔連帶責任，任壹方均不得就此向平臺追究任何責任；
           </p>
           <br></br>
           <p>
              4.本平臺致力於為用戶創建壹個合規、安全、可靠的數字資產交易環境，禁止用戶使用本平臺從事洗錢、走私、商業賄賂、惡意操縱市場等壹切非法交易活動或違法行為。為此我們盡最大努力制定嚴格的反洗錢反恐怖方案及采取有效的安全風險控制措施，以最大限度地保障用戶的合法權益，並使平臺自身維持著壹個良好的品牌聲譽。您需確保您的交易資金來源及交易資產均系合法取得，您亦承諾會遵守平臺相關的交易規則、反洗錢政策規定及相關國家的法律法規。如因您的交易涉嫌洗錢或其他非法交易行為的，相關者均有權向平臺舉報投訴或要求平臺介入核實對您采取包括但不限於限制或凍結違規賬號、移送通知相關權力機關等多項懲處，您確認不追究平臺因采取上述措施對您所造成的任何損失或影響；
           </p>
           <br></br>
           <p>
              5.當有權機關出示相應的調查文件要求本平臺配合對指定用戶進行調查時，或對用戶賬戶采取查封、凍結或者劃轉等措施時，本平臺將在核查司法文件的有效性和真實性後，按照經平臺判定的有權機關的要求協助提供相應的用戶數據，或進行相應的操作，因此造成的用戶隱私泄露、賬戶不能操作及由此給用戶所造成的其他損失等，本平臺不承擔任何責任；
           </p>
           <br></br>
           <p>
              6.在交易完成後，用戶使用交易所得數字資產或法幣資金，在第三方平臺上的所有投資及消費等行為，均為用戶個人行為，與平臺及廣告方無任何關系，平臺及廣告方不會為用戶在第三方平臺中的任何行為或損失承擔任何責任；
           </p>
           <br></br>
           <p>
              7.因平臺系統技術原因或賣家超時放幣或買家超時取消訂單等原因，導致對您有利的報價失效。您確認理解並接受上述情形，平臺並不對由此給您造成的損失或不利後果承擔任何責任；
           </p>
           <br></br>
           <p>
              8.在極端行情或所購標的數字資產價格存在極大波動情形下，若您惡意薅羊毛（即：不利己方的報價故意卡單拒絕成交），上述情形經平臺核實確認後，您同意平臺可以對您采取包括但不限於相應積分的扣除處理、限制提幣、限制您賬戶的部分功能等措施；
           </p>
           <br></br>
           <p>
              9.本平臺使用者因為違反本聲明的規定而觸犯塞舌爾共和國相關法律的，本平臺作為服務的提供方，有義務對平臺的規則及服務進行完善，但本站並無觸犯塞舌爾共和國相關法律的動機和事實，對使用者的行為不承擔任何連帶責任；
           </p>
           <br></br>
           <p>
              10.本協議未涉及的問題參見塞舌爾共和國有關法律法規，當本協議與塞舌爾共和國相關法律法規沖突時，以塞舌爾共和國相關法律法規為準。
           </p>
           <br></br>
           <h3>第四條 其他</h3>
           <br></br>
           <p>
              1.本協議自發布之日起正式實施，並將不時予以修改或完善，修改或完善後的新內容自公布之日起即對您生效；
           </p>
           <br></br>
           <p>
              2.本協議雖由買賣雙方自行協商確認簽署，但鑒於上述交易均系在本平臺上利用平臺服務功能進行，雙方應同時遵守平臺上其他相關的協議、規則及制度等的約束。
           </p>
           <br></br>
       </div>
      );
    }
  }

export default Bsagreement;