
import React from 'react';
import { Table, Tag, Space ,Pagination,Card} from 'antd';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import axios from '../public/axios'
import './Orderhistory.scss'
const columns = [
    {
      title: '訂單編號',
      dataIndex: 'order_no',
      key: 'order_no',
      render: text => <a>{text}</a>,
    },
    {
      title: '訂單類型',
      dataIndex: 'side',
      key: 'side',
      render: text => <div>
         {text=='BUY' &&<a style={{'color':'green'}}>買</a>}
         {text=='SELL' &&<a style={{'color':'red'}}>賣</a>}
      </div>,
    },
    {
      title: '訂單單價',
      dataIndex: 'coin_price',
      key: 'coin_price',
      render: text => <div>
         {text .toString().match(/^\d+(?:\.\d{0,2})?/)[0]}
      </div>,
    },
   
    {
        title: '訂單數量',
        dataIndex: 'amount',
        key: 'amount',
        render: text => <div>
         {text .toString().match(/^\d+(?:\.\d{0,4})?/)[0]}
      </div>,
      },
      
      {
        title: '訂單金額',
        dataIndex: 'legal_amount',
        key: 'legal_amount',
        render: text => <div>
         {text .toString().match(/^\d+(?:\.\d{0,2})?/)[0]}
      </div>,
      },
      {
        title: '訂單狀態',
        dataIndex: 'status',
        key: 'status',
      },
      
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
        
          <a onClick={()=> {gotodetail(record)}}><Link to="/OrderhistoryDetail">查看詳情</Link></a>
        </Space>
      ),
    },
  ];
  function gotodetail(record){
    window.localStorage.setItem('orderdetail', JSON.stringify(record))
    console.log(record)
  }
   
class Orderhistory extends React.Component {
    constructor(props){
        super(props)
        this.state={
            data:[],
            total:10,
        }
    }
    componentDidMount(){
        document.title="訂單歷史"
        this.getdata(1)
    }
    getdata(page){
      axios.get(`/v1/web/otc/order_list?page=${page}&per_page=10&order=0&currency=USDT`).then(res=>{
        if(res.code==0){
            let data = res.data
            data.map((item,index)=>{
                item.key = index
                if(item.status=='TRANSFER'){
                    item.status='轉單'
                }
                if(item.status=='CANCEL'){
                    item.status='已取消'
                }
                if(item.status=='COMPLETE'){
                    item.status='已完成'
                }
                if(item.status=='PAID'){
                    item.status='已付款'
                }
                if(item.status=='RECIVED'){
                    item.status='已收款'
                }
                if(item.status=='CREATED'){
                    item.status='未付款'
                }
                if(item.status=='RECICOMPLETE_CANCELVED'){
                    item.status='超時取消'
                }
               
            })
            this.setState({ data: res.data});
            this.setState({ total: res.meta.pagination.total});
        }
    })
    }
    onChange  = pageNumber => {
      this.getdata(pageNumber)
      // console.log('Page: ', this,pageNumber);
  }
    render() {
      return (
        <div className="user-l-p history-usdt">
           <Card title="訂單歷史" >
            <Table style={{'overflowX':'scroll','background':'white'}} 
            pagination={{ simple: true,hideOnSinglePage:true }} pagination={false} columns={columns} dataSource={this.state.data} >
              
            </Table>
            <br></br>
            <Pagination  simple defaultPageSize={10} defaultCurrent={1} total={this.state.total} onChange={this.onChange} />
            </Card>
        </div>
      );
    }
  }

export default Orderhistory;