import React, { Component } from 'react';
import { Carousel,Button ,Row, Col,Card,Input,Divider} from 'antd';
import './footer.scss'
class Footer extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    render() {
      return (
       <div>
           <Row className="otc-footer">
                <Col  md={24} lg={7} >
                         <br></br>
                         <p><img style={{  width: '120px' }} src={require('../static/logo_pay.png')} alt="logo" /></p>
                         <br></br>
                         <p>© 2020 XPay Global Limited</p>
                </Col>
                <Col  md={24} lg={3} >
                <br></br>
                        <p>服务支持</p>
                        <p>隱私政策</p>
                        <p>用戶協議 </p>
                        {/* <p>API文檔 </p> */}
                        <p>費率說明 </p>
                        <p>反洗錢計劃 </p>
                </Col>
                <Col  md={24} lg={3} >
                <br></br>
                        <p>核心優勢</p>
                        <p>定制方案</p>
                        {/* <p>快速接入</p>
                        <p>性能強大</p> */}
                        <p>資金安全</p>
                </Col>
                <Col  md={24} lg={3} >
                <br></br>
                        <p>關於我們</p>
                        <p>XPay介绍</p>
                        <p>申請合作</p>
                </Col>
                <Col  md={24} lg={4} >
                <br></br>
                        <p>客戶服務</p>
                        <p>service@xpay.pro</p>
                </Col>
                <Col  md={24} lg={1} >
                    {/* <p><img style={{  width: '220px' }} src={require('../static/logo_pay.png')} alt="logo" /></p> */}
                </Col>
           </Row>
       </div>
      );
    }
  }

export default Footer;