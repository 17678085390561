import React from 'react';
import Board from './Board';
import { Button } from 'antd';
class Game extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            history:[{
                squares:Array(9).fill(null)
            }],
            xIsNext:true,
            stepnumber:0
        }
    }
    handleClick(i) {
        const history = this.state.history.slice(0,this.state.stepnumber+1);
        const current = history[history.length-1]
        const squares = current.squares.slice()
        // console.log(history,current,squares)
        if (calculateWinner(squares) || squares[i]) {
        //   console.log(calculateWinner(squares) , squares[i])
         return;
       }
       squares[i] =  this.state.xIsNext?'X':'O' ;
       this.setState({
            history:history.concat([{
                squares:squares
            }]),
            stepnumber:history.length,
            xIsNext:!this.state.xIsNext
       });
     }
     jumpTo(step){
        this.setState({
            stepnumber:step,
            xIsNext:(step%2)===0
        })
     }
    render() {
        const history = this.state.history
        const current = history[this.state.stepnumber]
        const winner = calculateWinner(current.squares);
        let status;
        if(winner){
            status = 'winner' + winner
        }else{
            status = 'next player' + (this.state.xIsNext?'X':'O')
        }
        const moves = history.map((item,index)=>{
            const desc = index ? 'go to ' + index : 'go to come start '
            return (
                <li key={index}>
                    <button onClick={()=>{this.jumpTo(index)}}>{desc}</button>
                </li>
            )
        })
      return (
        <div className="game">

          <div className="game-board">
            <Board 
                squares={current.squares}
                onClick={(i)=>this.handleClick(i)}
            />
          </div>
          <div className="game-info">
            <div><Button>111</Button></div>
            <div>{status}</div>
            <ol>{moves}</ol>
          </div>
        </div>
      );
    }
  }
  
  // ========================================
  
export default Game;



function calculateWinner(squares) {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
    //  console.log(squares[a]?squares[a]:'','1111')
    //  console.log(squares[b]?squares[b]:'','2222')
    //  console.log(squares[c],'3333')
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return squares[a];
      }
    }
    return null;
  }