
import React from 'react';
import { Table, Tag, Space ,Pagination,Card} from 'antd';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import axios from '../public/axios'
import './Orderhistory.scss'
const columns = [
    {
        title: '訂單編號',
        dataIndex: 'orderNo',
        key: 'orderNo',
        render: text => <a>{text}</a>,
      },
    {
      title: '時間',
      dataIndex: 'created_time',
      key: 'created_time',
      render: text => <a>{text}</a>,
    },
    {
      title: '訂單類型',
      dataIndex: 'source',
      key: 'source',
      render: (text, record) => ( <div>
          
         <a style={{'color':record.color}}>{text}</a>
      </div>),
    },
    {
      title: '數量 (USDT)	',
      dataIndex: 'value',
      key: 'value',
      render: text => <div>
     { gotodetail(text)}
  </div>,
    },
  ];
  function gotodetail(text){
      if(text<0){
          let abstext = -(text)
          return -(abstext.toString().match(/^\d+(?:\.\d{0,4})?/)[0])
      }else{
        return (text.toString().match(/^\d+(?:\.\d{0,4})?/)[0])
      }
 
    
  }
 
class Orderhistory extends React.Component {
    constructor(props){
        super(props)
        this.state={
            data:[],
            total:10,
        }
    }
    componentDidMount(){
        document.title="資金明細"
        this.getdata(1)
    }
    getdata(page){
      axios.get(`/v1/account/amount/history?page=${page}&per_page=10&sortby=id&order=0&type=-1&symbol=USDT`).then(res=>{
        if(res.code==0){
            
            let data = res.data
            data.map((item,index)=>{
                item.key = index
                if(item.source=='BUY'){
                    item.source='買入'
                    item.color='green'
                }
                if(item.source=='SELL'){
                    item.source='賣出'
                    item.color='red'
                }
                if(item.source=='WITHDRAW_FEE_OUT'||item.source=='WITHDRAW_FEE_IN'){
                    item.source='提現手續費'
                    item.color='red'
                }
                if(item.source=='DEPOSIT'){
                    item.source='充值'
                    item.color='green'
                }
                if(item.source=='WITHDRAW'){
                    item.source='提現'
                    item.color='red'
                }
                if(item.source=='MERCHANTS_FEE'){
                    item.source='商戶平臺費用'
                    item.color='red'
                }
                if(item.source=='DEALERS_FEE'){
                    item.source='交易員平臺費用'
                    item.color='red'
                }
                if(item.source=='TRADERS_PLATFORM_FEE'){
                    item.source='代收商戶利潤'
                    item.color='red'
                }
                if(item.source=='MERCHANTS_SUBSIDY'){
                  item.source='补贴'
                  item.color='green'
              }
                
                
            })
            this.setState({ data: res.data});
            this.setState({ total: res.meta.pagination.total});
            // console.log(res.data)
        }
    })
    }
    onChange  = pageNumber => {
      this.getdata(pageNumber)
      // console.log('Page: ', this,pageNumber);
  }
    render() {
      return (
        <div className="user-l-p history-usdt">
           <Card title="資金明細" >
            <Table style={{'overflowX':'scroll','background':'white'}} 
           pagination={{ simple: true,hideOnSinglePage:true }}  pagination={false} columns={columns} dataSource={this.state.data} ></Table>
           <br></br>
           <Pagination  simple defaultPageSize={10} defaultCurrent={1} total={this.state.total} onChange={this.onChange} />
           </Card>
        </div>
      );
    }
  }

export default Orderhistory;