import React from 'react';
import { Card ,Modal,Input,Button,message} from 'antd';
import Icon,{PhoneOutlined,GoogleOutlined,ContainerOutlined,LockOutlined} from '@ant-design/icons';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";

import axios from '../public/axios'
import './security.scss'
const { Search } = Input;
class Security extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loadings:[],
            googleflag:true,
            userinfo:{},
            code:'',
            visible:false,
            psdvisible:false,
            phentervalue:'獲取驗證碼',
            phone:'',
            phonecode:'',
            password:'',
            timer:null
        }
    } 
    componentDidMount(){
      document.title="安全設置"
      axios.get('/v1/account/info').then(res=>{
          if(res.code==0){
              this.setState({ userinfo:res.data })
          }
      })
      this.getstatus()
    }
    getstatus(){
      axios.get('/v1/security/google/status').then(res=>{
        if(res.code==0){
            if(res.data==0){
                this.setState({ googleflag:false })
            }else{
               this.setState({ googleflag:true })
            }
        }
        })
    }
    gotoflag(type){
          this.setState({
                visible: true,
            });
    }
    changeemit(){
          this.setState({
            psdvisible: true,
        });
    }
    handleonSearch =(index)=>{
      if(this.state.phone==''){
          message.error('請輸入手機號');
         return
       }
      const newLoadings = [...this.state.loadings];
      newLoadings[index] = true;
      this.setState({
      loadings: newLoadings,
      });
      axios.post('/v1/account/reset_pwd/send_phone_code',{
        "account":this.state.phone,
      }).then(res=>{
          newLoadings[index] = false;
         this.setState({ loadings: newLoadings });
          if(res.code==0){
            message.success('已發送');
            this.changenumber()
          }else{
            message.error(res.msg);
          }
      })
    }
    changenumber = (index)=>{
          let num = 60;
           let timer =   setInterval(() => {
               num--;
                this.setState({ phentervalue: num });
               if(num<=0){
                     clearInterval(timer)
                    this.setState({ phentervalue:'獲取驗證碼' });
               }
           }, 1000);
           this.setState({
            timer: timer,
          });
  }
    componentWillUnmount(){
      clearInterval(this.state.timer);
    }
    emitpassword = (index)=>{
      if(this.state.phone==''){
        message.error('請輸入手機號');
       return
     }
     if(this.state.phonecode==''){
      message.error('請輸入驗證碼');
     return
     }
     if(this.state.password==''){
      message.error('請輸入新密碼');
     return
     }
      const newLoadings = [...this.state.loadings];
      newLoadings[index] = true;
      this.setState({
      loadings: newLoadings,
      });
      let obj = {
        "data":{
            "account":this.state.phone,
            "newpassword":this.state.password
        },
        "validata":{
            "phoneCode":this.state.phonecode
        }
       }
      axios.post('/v1/account/reset_pwd/update',obj).then(res=>{
        newLoadings[index] = false;
         this.setState({ loadings: newLoadings });
            if(res.code==0){
                this.setState({ phone: '' });
                this.setState({ password: '' });
                this.setState({ phonecode: '' });
                this.setState({ phentervalue:'獲取驗證碼' });
                this.setState({ psdvisible: false });
                
                clearInterval(this.state.timer);
                 message.success('修改成功');
             }else{
                message.error(res.msg);
             }
       })
    }
    goolesubmit(){
      if(this.state.code==''){
        message.error('請輸入谷歌驗證碼');
        return
    }
      const newLoadings = [...this.state.loadings];
      newLoadings[0] = true;
      this.setState({
        loadings: newLoadings,
      });
      
      
       let obj ={ 
            "validata": {
               "googleCode": this.state.code
           }
       }
       axios.post('/v1/security/google/unbind',obj).then(res=>{
          newLoadings[0] = false;
          this.setState({ loadings: newLoadings });
          if(res.code==0){
              message.success('解綁成功');   
              this.setState({
                visible: false,
              });
              this.getstatus()
          }else{
             message.success(res.msg);   
          }
       })
    }
    render() {
      const { userinfo ,googleflag,loadings} = this.state;
      return (
        <div className="user-l-p security-usdt">
           <Card title="基本信息" >
                 <p className="security-usdt-p"> 賬戶：<span className="fl-r">{userinfo.mobile}</span></p>
                 <p className="security-usdt-p"> 名稱：<span className="fl-r">{userinfo.name}</span></p>
           </Card>
           <br></br>
           <br></br>
           <br></br>
           <Card title="基本信息">
              
                 <p className="security-usdt-p">
                    <span><PhoneOutlined />：手機綁定</span>
                    <span>已綁定</span>
                    <span>解綁</span>
                  </p>
                 <p className="security-usdt-p">
                    <span><ContainerOutlined />：郵箱綁定</span>
                    <span>已綁定</span>
                    <span>解綁</span>
                   </p>
                 <p className="security-usdt-p">
                    <span> <GoogleOutlined />：谷歌驗證</span>
                    <span>{googleflag?'已綁定':'未綁定'}</span>
                     {googleflag&&  <a  onClick={()=>{this.gotoflag()}}>解綁</a>} 
                     {!googleflag&&<Link to="/set_google">綁定</Link>} 
                  </p>
                 <p className="security-usdt-p">
                    <span> <LockOutlined />：登錄密碼</span>
                    <span>*****</span>
                    <a onClick={()=>{this.changeemit()}}>修改</a>
                </p>
           </Card>
           <Modal
                centered
                title="解綁谷歌驗證碼"
                visible={this.state.visible}
                footer={null}
                maskClosable
               
                onCancel={()=>{this.setState({ visible:false });}}
                >
                <p><Input
                    placeholder="請輸入谷歌驗證碼"
                    size="large"
                    onChange={(e)=> this.setState({ code: e.target.value })}
                 /></p>
                 <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.goolesubmit()}} type="primary" className="btn" loading={loadings[0]} >
                         解綁
                     </Button>
                 </p>
            </Modal>
            <Modal
                centered
                title="修改登陸密碼"
                visible={this.state.psdvisible}
                footer={null}
                maskClosable
                onCancel={()=>{this.setState({ psdvisible:false });}}
                >
                <p><Input
                    placeholder="請輸入手機號碼"
                    size="large"
                    value={this.state.phone}
                    onChange={(e)=> this.setState({ phone: e.target.value })}
                 /></p>
                 <p><Search loading={loadings[1]}
                    placeholder="請輸入驗證碼"
                    enterButton={this.state.phentervalue}
                    value={this.state.phonecode}
                    size="large"
                    onSearch={()=>{this.handleonSearch(1)}}
                    onChange={(e)=> this.setState({ phonecode: e.target.value })}
                    /></p>
                 <p><Input
                    placeholder="請輸入新密碼"
                    value={this.state.password}
                    size="large"
                    onChange={(e)=> this.setState({ password: e.target.value })}
                 /></p>
                 <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.emitpassword(2)}} type="primary" className="btn" loading={loadings[2]} >
                         修改
                     </Button>
                 </p>
            </Modal>
        </div>
      );
    }
  }

export default Security;