import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch,Redirect } from 'react-router-dom';
import Game from '../Game';

import indexpage from '../index/indexpage';

import user from '../user/index';

import Login from '../login/login';
import page from '../page/index';
import App from '../App';

import {routerConfig}  from "./route.config";
const BasicRoute = () => (
    <BrowserRouter>
        <Switch>
            {/* {routerConfig.routes.map((item, ind) => {
              return (
                <Route key={ind} path={item.path} component={item.component}>
                </Route>
              );
            })} */}
             <Route exact path="/"
             render={() => (
               
                    <Redirect to="/index"/>
              
                )}></Route>

             <Route exact path="/index" component={indexpage} ></Route>
             <Route exact path="/Mas" component={indexpage}></Route>
             <Route exact path="/Trader" component={indexpage}></Route>
             <Route exact path="/Faq" component={indexpage}></Route>
             <Route exact path="/About" component={indexpage}></Route>
             <Route exact path="/Login" component={indexpage}></Route>
             <Route exact path="/register" component={indexpage}></Route>

             <Route exact path="/Trade" component={user}></Route>
             <Route exact path="/wallets" component={user}></Route>
             <Route exact path="/Orderhistory" component={user}></Route>
             <Route exact path="/OrderhistoryDetail" component={user}></Route>
             <Route exact path="/Listofassets" component={user}></Route>
             <Route exact path="/Deposit" component={user}></Route>
             <Route exact path="/Withdraw" component={user}></Route>
             <Route exact path="/security" component={user}></Route>
             <Route exact path="/uppassword" component={user}></Route>
             <Route exact path="/set_google" component={user}></Route>
            
             <Route exact path="/kycCertification" component={user}></Route>
             
             <Route exact path="/Collectionlist" component={user}></Route>
             <Route exact path="/CollectionlistSet" component={user}></Route>


             {/* <Route exact path="/index" component={page}></Route>
             
             
             <Route exact path="/child" component={page} /> */}
        </Switch>
    </BrowserRouter>
);


export default BasicRoute;